<template>
	<div>
		<div
			v-if="!isMobile"
			@click="onClick"
			class="back-to-top"
			v-show="isOpened"
		></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isOpened: false,
			isScrolling: false,
			timer: null,
			isMobile: this.$userAgent.isMobile
		};
	},

	methods: {
		getScrollTop() {
			// 兼容不同的浏览器

			return (
				document.documentElement.scrollTop ||
				window.pageYOffset ||
				document.body.scrollTop
			);
		},

		setScrollTop(val) {
			document.documentElement.scrollTop &&
				(document.documentElement.scrollTop = val);
			window.pageYOffset && (window.pageYOffset = val);
			document.body.scrollTop && (document.body.scrollTop = val);
		},

		onScroll() {
			const scrollTop = this.getScrollTop();
			this.isOpened = scrollTop > 0;
		},

		onClick() {
			if (this.isScrolling) {
				return;
			}

			this.isScrolling = true;

			// 50px/17ms速度实现滚动
			this.timer = setInterval(() => {
				let scrollTop = this.getScrollTop();
				scrollTop = scrollTop - 50;

				this.setScrollTop(scrollTop > 0 ? scrollTop : 0);

				if (scrollTop <= 0) {
					clearInterval(this.timer);
					this.isScrolling = false;
				}
			}, 17);
		}
	},

	mounted() {
		window.addEventListener("scroll", this.onScroll);
	}
};
</script>

<style lang="less" scoped>
.back-to-top {
	position: fixed;
	z-index: 9999;
	right: 2.5%;
	bottom: 2.5%;
	cursor: pointer;
	width: 60px;
	height: 60px;
	background-image: url("../assets/images/btn_up1.png");
	background-size: 100% 100%;
	background-position: 100% 100%;

	&:hover {
		background-image: url("../assets/images/btn_up2.png");
	}
}
</style>
