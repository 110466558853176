<template>
    <div class="footer">
        <b-container>
            
            <div class="link">
                <base-link to="/abouts" class="link-item">关于我们</base-link>
                <base-link
                    :to="{
                        path: '/questions',
                        query: {
                            itemUrl: 'feedback'
                        }
                    }"
                    class="link-item"
                    >联系客服</base-link
                >
                <base-link
                    :to="{
                        path: '/questions',
                        query: {
                            itemUrl: 'questions'
                        }
                    }"
                    class="link-item"
                    >常见问题</base-link
                >
            </div>

            <div class="company">深圳市维体运动科技有限公司 | 兜兜龙科技有限公司</div>
            
            <a class="icp" href="https://app.eduyun.cn/bmp-web/">教育应用备案:4401622号</a>
            |<a class="icp" href="https://beian.miit.gov.cn/">{{beian}}</a>
            <!-- |<a class="icp" @click="show_www = true">网络文化经营许可证</a> -->
            |<a class="icp" @click="show_dxzz = true">增值电信业务许可证</a>
        </b-container>
        
        <div class="public" v-if="isQrcodeOpened">
            <div class="public-title">获取更多学习内容</div>
            <img
                src="@/assets/images/public_qrcode.jpg"
                class="public-qrcode"
            />
            <div class="public-msg" >
                <p>关注微信公众号</p>
                <p>“兜兜龙同步学”</p>
            </div>
        </div>
        <div v-if="show_www" class="show_img" @click="show_www = false">
            <img
                @click="show_www = false"
                src="@/assets/images/www.jpg"
                class="show_img-center_pic"
            />
        </div>
        <div v-if="show_dxzz" class="show_img" @click="show_dxzz = false">
            <img
                @click="show_dxzz = false"
                src="@/assets/images/dxzz.jpg"
                class="show_img-center_pic"
            />
        </div>
    </div>
</template>

<script>
import BaseLink from "@/components/base-link.vue";

export default {
    components: {
        BaseLink
    },

    props: {
        isQrcodeOpened: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show_www:false,
            show_dxzz:false,
            beian: "粤ICP备18012024号-1",
        };
    },

    methods: {
        change_beian(){
            let host = window.location.host;
            if(host.indexOf("duduro.com.cn") >= 0){
                this.beian = "粤ICP备18012024号";
            }else if(host.indexOf("duduro.cn") >= 0){
                this.beian = "粤ICP备18012024号";
            }else {
                this.beian = "粤ICP备18012024号";
            }
        }
    },
     mounted() {
        this.change_beian();
    }
};
</script>

<style lang="less">
.footer {
    background: #58cde4;
    max-height: 165px;
    padding: 35px 0 46px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    position: relative;
}

.link {
    display: flex;
    justify-content: center;

    a {
        color: #fff !important;
    }

    &-item {
        padding: 0 30px;
        line-height: 1;

        + .link-item {
            border-left: 1px solid #fff;
        }
    }
}

.company {
    margin: 20px 0 10px;
}
.show_img {
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    &-center_pic {
        max-width: 100vw;
        margin-top: 10vh;
        // left: 10vw;
        // top:10vh;
        max-height:80vh;
        position: absolute;
        transform: translate(-50%, -00%);
    }
}
.public {
    width: 240px;
    height: 300px;
    position: absolute;
    right: 40px;
    bottom: 75px;
    background-image: url("../assets/images/container/products/public_bg.png");
    background-size: 100% 100%;
    background-position: 0 0;
    font-size: 24px;
    padding: 30px 30px 0 30px;

    &-title {
        width: 240px;
        color: #383838;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, -100%);
    }

    &-msg {
        color: #fff;

        p {
            margin-bottom: 0;
        }
    }

    &-qrcode {
        width: 184px;
        height: 184px;
        background: #fff;
        margin-bottom: 20px;
    }
}

@media (max-width: 1024px) {
    .footer {
        padding: calc(50 / 1080 * 100vw) 0 calc(63 / 1080 * 100vw);
        font-size: calc(27 / 1080 * 100vw);
        max-height: none;
    }

    .link {
        &-item {
            padding: 0 calc(30 / 1080 * 100vw);
        }
    }

    .company {
        margin: calc(30 / 1080 * 100vw) 0 calc(18 / 1080 * 100vw);
    }
}
</style>
