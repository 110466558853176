var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('b-container',[_c('div',{staticClass:"link"},[_c('base-link',{staticClass:"link-item",attrs:{"to":"/abouts"}},[_vm._v("关于我们")]),_c('base-link',{staticClass:"link-item",attrs:{"to":{
                    path: '/questions',
                    query: {
                        itemUrl: 'feedback'
                    }
                }}},[_vm._v("联系客服")]),_c('base-link',{staticClass:"link-item",attrs:{"to":{
                    path: '/questions',
                    query: {
                        itemUrl: 'questions'
                    }
                }}},[_vm._v("常见问题")])],1),_c('div',{staticClass:"company"},[_vm._v("深圳市维体运动科技有限公司 | 兜兜龙科技有限公司")]),_c('a',{staticClass:"icp",attrs:{"href":"https://app.eduyun.cn/bmp-web/"}},[_vm._v("教育应用备案:4401622号")]),_vm._v(" |"),_c('a',{staticClass:"icp",attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v(_vm._s(_vm.beian))]),_vm._v(" |"),_c('a',{staticClass:"icp",on:{"click":function($event){_vm.show_dxzz = true}}},[_vm._v("增值电信业务许可证")])]),(_vm.isQrcodeOpened)?_c('div',{staticClass:"public"},[_c('div',{staticClass:"public-title"},[_vm._v("获取更多学习内容")]),_c('img',{staticClass:"public-qrcode",attrs:{"src":require("@/assets/images/public_qrcode.jpg")}}),_vm._m(0)]):_vm._e(),(_vm.show_www)?_c('div',{staticClass:"show_img",on:{"click":function($event){_vm.show_www = false}}},[_c('img',{staticClass:"show_img-center_pic",attrs:{"src":require("@/assets/images/www.jpg")},on:{"click":function($event){_vm.show_www = false}}})]):_vm._e(),(_vm.show_dxzz)?_c('div',{staticClass:"show_img",on:{"click":function($event){_vm.show_dxzz = false}}},[_c('img',{staticClass:"show_img-center_pic",attrs:{"src":require("@/assets/images/dxzz.jpg")},on:{"click":function($event){_vm.show_dxzz = false}}})]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"public-msg"},[_c('p',[_vm._v("关注微信公众号")]),_c('p',[_vm._v("“兜兜龙同步学”")])])
}]

export { render, staticRenderFns }