export default [
    {
        name: "产品介绍",
        url: "/",
        list: []
    },
    // {
    //     name: "同步视频",
    //     url: "/videos",
    //     list: []
    // },
    {
        name: "关于我们",
        url: "/abouts",
        list: [
            {
                name: "公司简介",
                url: "brief"
            },
            {
                name: "商务合作",
                url: "business"
            },
            // {
            //     name: "加入我们",
            //     url: "join"
            // },
            // {
            //     name: "最新动态",
            //     url: "news"
            // }
        ]
    },
    {
        name: "下载中心",
        url: "/downloads",
        list: []
    },
    {
        name: "常见问题",
        url: "/questions",
        list: [
            {
                name: "常见问题",
                url: "questions"
            },
            {
                name: "问题反馈",
                url: "feedback"
            }
        ]
    }
];
