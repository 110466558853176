<template>
    <div class="m-nav-item">
        <div
            @click="onNavItemClick(href)"
            :class="[
                'm-nav-item__parent',
                isOpened && 'm-nav-item__parent--opened'
            ]"
        >
            <slot></slot>

            <template v-if="list.length">
                <img
                    v-if="isOpened"
                    src="@/assets/images/mobile/arrow_0.png"
                    class="m-nav-item__arrow"
                />

                <img
                    v-else
                    src="@/assets/images/mobile/arrow_1.png"
                    class="m-nav-item__arrow"
                />
            </template>
        </div>

        <div v-show="isOpened && list.length" class="list">
            <base-link
                class="list-item"
                v-for="(item, idx) in list"
                :key="idx"
                :to="{
                    path: href,
                    query: { itemUrl: item.url }
                }"
                @on-click="onBaseLinkClick"
            >
                <div>{{ item.name }}</div>
            </base-link>
        </div>
    </div>
</template>

<script>
import BaseLink from "@/components/base-link.vue";

export default {
    components: {
        BaseLink
    },

    props: {
        list: {
            type: Array,
            default: () => []
        },
        href: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            isOpened: false
        };
    },

    computed: {
        // 当前页
        current() {
            return (
                this.$route.path === "/" + this.href ||
                this.$route.path === this.href
            );
        }
    },

    methods: {
        onNavItemClick(href) {
            if (!this.list.length) {
                this.$router.push({
                    path: href
                });
                return;
            }

            this.isOpened = !this.isOpened;
        },

        onBaseLinkClick() {
            console.log(2);

            this.$parent.onClose();
        }
    }
};
</script>

<style lang="less" scoped>
.m-nav-item {
    a {
        color: #383838;
        text-decoration: none !important;
    }

    &__parent {
        height: calc(100vw / 1080 * 180);
        line-height: calc(100vw / 1080 * 180);
        font-size: calc(100vw / 1080 * 48);
        padding-left: calc(100vw / 1080 * 80);

        &--opened {
            color: #fbc73c !important;
        }
    }

    &__arrow {
        width: calc(100vw / 1080 * 42);
        margin-left: calc(100vw / 1080 * 18);
    }
}

.list {
    line-height: calc(100vw / 1080 * 180);
    font-size: calc(100vw / 1080 * 48);

    &-item {
        > div {
            background-image: url("../assets/images/mobile/item_bg.png");
            background-size: 100% 100%;
            background-position: 0 0;
            height: calc(100vw / 1080 * 180);
            padding-left: calc(100vw / 1080 * 80);
        }
    }
}
</style>
