<template>
    <div @mouseover="onMouseover" @mouseout="onMouseout" :class="['nav-item']">
        <base-link
            :to="href"
            :class="[
                'navbar-item',
                (isOpened || current) && 'navbar-item--opened',
                !list.length && 'navbar-item--pointer'
            ]"
        >
            <slot></slot>
        </base-link>

        <div v-show="isOpened && list.length" class="list">
            <base-link
                class="list-item"
                :to="{
                    path: href,
                    query: { itemUrl: item.url }
                }"
                v-for="(item, idx) in list"
                :key="idx"
            >
                <div>{{ item.name }}</div>
            </base-link>
        </div>
    </div>
</template>

<script>
import BaseLink from "@/components/base-link.vue";

export default {
    components: {
        BaseLink
    },

    props: {
        list: {
            type: Array,
            default: () => []
        },
        href: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            isOpened: false,
            openedTimer: null
        };
    },

    computed: {
        // 当前页
        current() {
            return (
                this.$route.path === "/" + this.href ||
                this.$route.path === this.href
            );
        }
    },

    methods: {
        onMouseover() {
            clearTimeout(this.openedTimer);
            this.isOpened = true;
        },

        onMouseout() {
            this.openedTimer = setTimeout(() => {
                !this.focus && (this.isOpened = false);
            }, 200);
        }
    },

    watch: {}
};
</script>

<style lang="less">
@media (min-width: 992px) {
    .nav-item {
        position: relative;

        + .nav-item {
            margin-left:calc(100vw / 1920 * 10);
        }

        a {
            text-decoration: none !important;
        }
    }

    .navbar {
        &-item {
            width: calc(100vw / 1920 * 130);
            color: #383838 !important;
            font-size: calc(100vw / 1920 * 20);
            position: relative;
            z-index: 9;
            text-align: center;
            display: inline-block;
            padding: calc(100vw / 1920 * 13.5) 0 calc(100vw / 1920 * 16.5);
            line-height: 1;

            &--opened {
                border-radius: 25px;
                background: #fbc73c;
                color: #fff !important;
            }

            &--pointer {
                cursor: pointer;
            }
        }
    }

    .list {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
        width: calc(100vw / 1920 * 160);
        border: 2px solid #fbc73c;
        padding: calc(100vw / 1920 * 20) 0 calc(100vw / 1920 * 10);
        border-radius: 20px;
        background: #fff;

        &-item {
            color: #383838;
            font-size: calc(100vw / 1920 * 20);
            height: calc(100vw / 1920 * 40);
            line-height: calc(100vw / 1920 * 40);
            text-align: center;
            cursor: pointer;
            display: block;
            text-align: center;

            + .list-item {
                border-top: 2px #eeeeee dashed;
                height: calc(100vw / 1920 * 42);
                line-height: calc(100vw / 1920 * 40);
            }

            &:hover {
                color: #fbc73c;
            }
        }
    }
}
</style>
