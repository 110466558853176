<template>
    <div class="base-link" @click="onClick">
        <slot></slot>
    </div>
</template>

<script>
import { onScrollTo } from "@/utils";

export default {
    props: {
        to: [Object, String]
    },

    methods: {
        onClick() {
            this.$emit("on-click");

            if (this.isCurrent) {
                onScrollTo(this.itemUrl);
            } else {
                this.push();
            }
        },

        push() {
            let to = this.to;

            if (typeof to === "string") {
                to = {
                    path: to
                };
            }

            this.$router.push({
                ...to
            });
        }
    },

    computed: {
        path() {
            return typeof this.to === "string" ? this.to : this.to.path;
        },

        itemUrl() {
            return typeof this.to === "string" ? null : this.to.query.itemUrl;
        },

        // 判断是否当前页
        isCurrent() {
            console.log(`${this.path}?itemUrl=${this.itemUrl}`);

            return (
                this.$route.fullPath === `${this.path}?itemUrl=${this.itemUrl}`
            );
        }
    }
};
</script>

<style lang="less" scoped>
.base-link {
    cursor: pointer;
}
</style>
