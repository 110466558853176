<template>
    <div v-show="isOpend" class="m-nav">
        <div @click="onMaskClick" class="m-nav-mask"></div>

        <div class="m-nav-list">
            <mobile-nav-item
                v-for="(item, idx) in navs"
                :key="idx"
                :href="item.url"
                :list="item.list"
                >{{ item.name }}</mobile-nav-item
            >
        </div>
    </div>
</template>

<script>
import navs from "@/config/navs.js";
import MobileNavItem from "@/components/mobile-nav-item.vue";

export default {
    props: ["value"],

    components: {
        MobileNavItem
    },

    data() {
        return {
            isOpend: false,
            navs
        };
    },

    methods: {
        onMaskClick() {
            this.onClose();
        },

        onClose() {
            this.isOpend = false;
            this.$emit("input", false);

            console.log(1)
        }
    },

    watch: {
        value(newVal) {
            this.isOpend = newVal;
        }
    }
};
</script>

<style lang="less" scoped>
.m-nav {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

    &-mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
    }

    &-list {
        width: calc(100vw / 1080 * 400);
        height: 100vh;
        padding-top: calc(100vw / 1080 * 210);
        padding-bottom: calc(100vw / 1080 * 210);
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        overflow-y: scroll;
    }
}
</style>
