var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['nav-item'],on:{"mouseover":_vm.onMouseover,"mouseout":_vm.onMouseout}},[_c('base-link',{class:[
            'navbar-item',
            (_vm.isOpened || _vm.current) && 'navbar-item--opened',
            !_vm.list.length && 'navbar-item--pointer'
        ],attrs:{"to":_vm.href}},[_vm._t("default")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpened && _vm.list.length),expression:"isOpened && list.length"}],staticClass:"list"},_vm._l((_vm.list),function(item,idx){return _c('base-link',{key:idx,staticClass:"list-item",attrs:{"to":{
                path: _vm.href,
                query: { itemUrl: item.url }
            }}},[_c('div',[_vm._v(_vm._s(item.name))])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }