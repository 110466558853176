<template>
    <div :class="['nav', isFixed && 'nav--fixed']">
        <b-container>
            <b-navbar class="navbar" toggleable="lg">
                <b-navbar-brand class="brand" href="#">
                    <img class="brand-logo" src="@/assets/images/logo.png" />

                    <picture>
                        <source
                            media="(max-width: 1024px)"
                            srcset="@/assets/images/mobile/title.png"
                        />
                        <source
                            media="(min-width: 1025px)"
                            srcset="@/assets/images/title.png"
                        />
                        <img
                            class="brand-company"
                            src="@/assets/images/title.png"
                        />
                    </picture>
                </b-navbar-brand>

                <b-collapse id="navbar-collapse" is-nav>
                    <b-navbar-nav class="ml-auto">
                        <template v-if="!isMobile">
                            <nav-item
                                v-for="(item, idx) in navs"
                                :key="idx"
                                :href="item.url"
                                :list="item.list"
                                :fixed="isFixed"
                                >{{ item.name }}</nav-item
                            >
                        </template>
                    </b-navbar-nav>
                </b-collapse>

                <img
                    v-if="isMobile"
                    class="nav-toggle"
                    src="@/assets/images/mobile/list.png"
                    @click="onNavToggleClick"
                />
            </b-navbar>
        </b-container>

        <MobileNav v-model="isMobileNavOpened" />
    </div>
</template>

<script>
import navs from "@/config/navs.js";
import NavItem from "@/components/nav-item.vue";
import MobileNav from "@/components/mobile-nav.vue";

export default {
    components: {
        NavItem,
        MobileNav
    },

    props: {},

    data() {
        return {
            navs,
            isFixed: false,
            isMobileNavOpened: false,
            isMobile: this.$userAgent.isMobile
        };
    },

    methods: {
        getScrollTop() {
            // 兼容不同的浏览器

            return (
                document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop
            );
        },

        onScroll() {
            const scrollTop = this.getScrollTop();
            this.isFixed = scrollTop > 0;
        },

        onNavToggleClick() {
            this.isMobileNavOpened = true;
        }
    },

    computed: {},

    mounted() {
        window.addEventListener("scroll", this.onScroll);
    }
};
</script>

<style lang="less" scoped>
.navbar {
    min-height: 80px;
    line-height: 80px;
    padding: 0;
    width: (100vw / 1920 * 1200);
}

.brand {
    display: flex;
    align-items: center;

    &-logo {
        width: (100vw / 1920 * 44);
    }

    &-company {
        width: (100vw / 1920 * 208);
        margin-left: (100vw / 1920 * 10);
    }
}

.nav {
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;

    &--fixed {
        position: fixed;
    }

    .container {
        padding: 0 !important;
        width: (100vw / 1920 * 1200);
    }
}

@media (max-width: 1024px) {
    .nav {
        .container {
            padding: 0;
            width: 100vw !important;
        }

        &-toggle {
            width: calc(100vw / 1080 * 72);
        }
    }

    .navbar {
        line-height: calc(100vw / 1080 * 140);
        min-height: calc(100vw / 1080 * 140);
        padding-left: calc(100vw / 1080 * 50);
        padding-right: calc(100vw / 1080 * 70);
        width: 100vw !important;
    }

    .brand {
        &-logo {
            width: calc(100vw / 1080 * 86);
        }

        &-company {
            display: inline-block;
            margin-left: calc(100vw / 1080 * 20);
            color: #383838;
            font-size: calc(100vw / 1080 * 48);
            width: calc(100vw / 1080 * 380);
        }
    }
}
</style>
